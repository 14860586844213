import React, { useRef, useEffect } from "react";
import "./Contact.css";
import { Typography, Grid, Paper, Box } from "@mui/material";
import * as THREE from "three";
import ParticleComponent from "./ParticleComponent.js";
import FOG from "vanta/dist/vanta.fog.min.js";
import { Mail } from "lucide-react";
import { LinkedIn } from "@mui/icons-material";

const ContactItem = ({ icon, text, link }) => (
  <Box display="flex" alignItems="center" mb={2}>
    {icon}
    <Typography variant="body1" ml={1}>
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1a73e8", textDecoration: "none" }}
        >
          {text}
        </a>
      ) : (
        text
      )}
    </Typography>
  </Box>
);

const Contact = () => {
  const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect = null;
    vantaEffect = FOG({
      el: vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      highlightColor: 0xf0b01,
      midtoneColor: 0x69d82,
      baseColor: 0x0,
      speed: 3,
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  return (
    <>
      <div style={{ position: "absolute", top: 0, zIndex: 0 }}>
        <ParticleComponent width="50%" height="50%" />
      </div>
      <Box
        ref={vantaRef}
        className="workContainer"
        fullWidth
        style={{
          height: "85vh",
          boxShadow: "none",
          position: "relative",
          top: 0,
        }}
      >
        <Grid
          container
          spacing={4}
          justifyContent="center"
          style={{ boxShadow: "none", marginTop: "20px" }}
        >
          <Grid item xs={12} md={3}>
            <Paper
              className="p-6"
              style={{
                backgroundColor: "rgba(240, 240, 240, 0.3)",
                backdropFilter: "blur(10px)",
              }}
            >
              <Box
                flexDirection="column"
                alignItems="center"
                style={{
                  boxShadow: "none",
                  backgroundColor: "rgba(240, 240, 240, 0.8)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <Typography
                  variant="h4"
                  className="text-xl font-semibold mb-4 p-4"
                  align="center"
                >
                  Contact Information
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    justifyContent: "center",
                    marginTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    flexWrap="wrap"
                  >
                    <Box display="flex" alignItems="center" margin="0 20px">
                      <ContactItem
                        icon={<Mail className="text-gray-600" />}
                        text="merlinjacksoneng@gmail.com"
                        link="mailto:merlinjacksoneng@gmail.com"
                      />
                    </Box>
                    <Box display="flex" alignItems="center" margin="0 20px">
                      <ContactItem
                        icon={<LinkedIn className="text-gray-600" />}
                        text="LinkedIn Profile"
                        link="https://www.linkedin.com/in/merlup"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contact;
