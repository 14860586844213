import React, { useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  Avatar,
  List,
  ListItem,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import * as THREE from "three";
import "./Home.css";
import mapImage from "./assets/map.png";
import mePic from "./assets/me_pic.png";
import PortfolioContent from "./PortfolioContent";
import ParticleComponent from "./ParticleComponent.js";
import FOG from "vanta/dist/vanta.fog.min.js";

const Home = () => {
  const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect = null;
    vantaEffect = FOG({
      el: vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,

      highlightColor: 0xf0b01,
      midtoneColor: 0x69d82,
      baseColor: 0x0,
      speed: 3,
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  return (
    <div>
      <div style={{ position: "absolute", top: 0, zIndex: 0 }}>
        <ParticleComponent width="50%" height="50%" />
      </div>

      <div className="home-container">
        <div
          className="hero-section"
          style={{
            position: "relative",
            zIndex: 1,
            padding: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className="avatar-container">
            <Avatar
              id="avatar_img"
              class="avatar-img"
              src={mePic}
              sx={{ backgroundColor: "white" }}
              alt="Merlin Jackson"
            />
          </div>
          <div className="details-container">
            <Typography variant="h2" className="title">
              Software Engineer
            </Typography>
          </div>
        </div>
        <Box style={{ position: "relative" }}>
          <Grid
            ref={vantaRef}
            container
            className="about-container"
            style={{ zIndex: 1 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3}
              style={{ zIndex: 1, background: "none" }}
              className="info-section"
            >
              <Card
                className="info-card"
                style={{ background: "none", boxShadow: "none" }}
              >
                <CardContent
                  style={{
                    color: "white",
                  }}
                >
                  <Typography
                    variant="h4"
                    align="center"
                    pb={2}
                    pt={2}
                    style={{
                      backgroundColor: "rgba(47, 79, 79, 0.8)",
                      backdropFilter: "blur(10px)",
                      borderRadius: "5px",
                    }}
                  >
                    Looking for someone amazing?
                  </Typography>
                  <CardMedia
                    component="img"
                    height="300"
                    image={mapImage}
                    alt="MapImage"
                  />
                  <Typography
                    variant="body1"
                    align="center"
                    style={{
                      backgroundColor: "rgba(240, 240, 240, 0.8)",
                      backdropFilter: "blur(10px)",
                      padding: "20px",
                      color: "black",
                    }}
                  >
                    Currently living in Hoboken, New Jersey. Open to all
                    adventures. I'm available for FullTime, Freelance & Contract
                    Positions.
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/contact"
                    className="info-button"
                    style={{ marginTop: "10px" }}
                  >
                    Contact Me
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ marginLeft: { xs: 1, sm: 8, md: 0, lg: 0 } }}
              sm={10}
              md={8}
              lg={5}
              className="about-section"
            >
              <div className="about-info">
                <Card
                  className="info-card full-width"
                  style={{
                    zIndex: 1,
                    background: "none",
                  }}
                >
                  <Typography variant="h4" className="info-card-title">
                    About Me
                  </Typography>
                  <CardContent style={{}} className="info-card-content">
                    <PortfolioContent />
                  </CardContent>
                </Card>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              mt={3}
              className="about-work"
              style={{
                zIndex: 1,
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={8}
                  sm={5}
                  md={3.5}
                  lg={5}
                  sx={{
                    marginLeft: { xs: 10, sm: 8, md: 46.5, lg: 0 },
                    marginRight: { xs: 5, sm: 5, md: 5, lg: 5 },
                  }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    className="info-card-title"
                    style={{
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    Work Experience
                  </Typography>
                  <Card
                    className="info-card"
                    style={{
                      backgroundColor: "rgba(240, 240, 240, 0.8)",
                      backdropFilter: "blur(10px)",
                      color: "black",
                    }}
                  >
                    <CardContent>
                      <List variant="body2">
                        <ListItem>
                          • Intel: (2021-2024) Software Engineer
                        </ListItem>
                        <ListItem>
                          • ActionFace: (2020-2021) Full Stack Software Engineer
                        </ListItem>
                        <ListItem>
                          • Intel: (2017-2020) Systems Engineer
                        </ListItem>
                        <ListItem>
                          • Contracts.org: (2015-2016) Software Engineer
                        </ListItem>
                        <ListItem>
                          • Famo.us: (2015-2016) Video Production
                        </ListItem>
                        <ListItem>
                          • Money & Tech: (2015-2016) Video Editor
                        </ListItem>
                        <ListItem>
                          • Freelance: (2014-2016) Web Developer
                        </ListItem>
                        <ListItem>
                          • Juxtapoz Magazine: (2010-2014) Contributing
                          Photographer
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={5}
                  md={3.5}
                  lg={5}
                  sx={{ marginLeft: { xs: 10, sm: 0, md: 5, lg: 0 } }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    className="info-card-title"
                    style={{
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    Education
                  </Typography>
                  <Card
                    className="info-card"
                    style={{
                      backgroundColor: "rgba(240, 240, 240, 0.8)",
                      backdropFilter: "blur(10px)",
                      color: "black",
                    }}
                  >
                    <CardContent>
                      <List variant="body2">
                        <ListItem>
                          • UWM-Milwaukee: (2006-2008) Graphic Design
                        </ListItem>
                        <ListItem>
                          • City College of San Francisco: (2008-2010) Graphic
                          Design
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div
            className="parallax-section"
            style={{ zIndex: 1, backgroundColor: "rgba(0,0,0, 0.3)" }}
          >
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/work"
              className="parallax-button"
            >
              View My Work
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Home;
