import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Modal,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const ThumbnailImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  transition: "opacity 0.3s ease-in-out",
  "&:hover": {
    opacity: 0.7,
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "1200px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  outline: "none",
  borderRadius: theme.shape.borderRadius,
}));

const ProjectMedia = ({ project }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNext = () => {
    setCurrentImage((prev) => (prev + 1) % project.images.length);
  };

  const handlePrev = () => {
    setCurrentImage(
      (prev) => (prev - 1 + project.images.length) % project.images.length
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentImage(index);
    setIsModalOpen(true);
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ position: "relative", mb: 2 }}>
        <StyledImage
          src={project.images[currentImage]}
          alt={`${project.title} main`}
          sx={{ height: 400 }}
        />
        <IconButton
          onClick={handlePrev}
          sx={{
            position: "absolute",
            left: 8,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <IconButton
          onClick={handleNext}
          sx={{
            position: "absolute",
            right: 8,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      <Grid container spacing={1}>
        {project.images.map((image, index) => (
          <Grid item xs={3} key={index}>
            <ThumbnailImage
              src={image}
              alt={`${project.title} ${index + 1}`}
              onClick={() => handleThumbnailClick(index)}
            />
          </Grid>
        ))}
      </Grid>

      {project.video && project.video.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Project Videos
          </Typography>
          <Grid container spacing={2}>
            {project.video.map((video, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box
                  component="iframe"
                  src={video}
                  title={`${project.title} video ${index + 1}`}
                  width="100%"
                  height={isMobile ? "200" : "315"}
                  sx={{ border: 0, borderRadius: theme.shape.borderRadius }}
                  allowFullScreen
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="image-modal"
        aria-describedby="full-size-project-image"
      >
        <ModalContent>
          <IconButton
            onClick={() => setIsModalOpen(false)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={project.images[currentImage]}
            alt={`${project.title} full size`}
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "80vh",
              objectFit: "contain",
            }}
          />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProjectMedia;
