import React, { useRef, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Container, Typography, Paper, Box } from "@mui/material";
import * as THREE from "three";
import ParticleComponent from "./ParticleComponent.js";
import FOG from "vanta/dist/vanta.fog.min.js";

const data = [
  { name: "React", years: 5 },
  { name: "Material-UI", years: 3 },
  { name: "JavaScript", years: 14 },
  { name: "HTML", years: 14 },
  { name: "CSS", years: 14 },
  { name: "REST APIs", years: 12 },
  { name: "Node.js", years: 6 },
  { name: "JIRA", years: 8 },
  { name: "Agile", years: 10 },
  { name: "Scrum", years: 10 },
  { name: "Three.JS", years: 1 },
  { name: "Ruby on Rails", years: 6 },
  { name: "Python", years: 6 },
  { name: "Linux", years: 6 },
  { name: "PostgreSQL", years: 5 },
  { name: "Heroku", years: 5 },
  { name: "Git", years: 8 },
  { name: "MySQL", years: 6 },
  { name: "Machine Learning", years: 2 },
  { name: "AWS Polly", years: 2 },
  { name: "Unity", years: 3 },
  { name: "C#", years: 3 },
];

const Skills = () => {
  const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect = null;
    vantaEffect = FOG({
      el: vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,

      highlightColor: 0xf0b01,
      midtoneColor: 0x69d82,
      baseColor: 0x0,
      speed: 3,
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);
  return (
    <>
      <div style={{ position: "absolute", top: 0, zIndex: 0 }}>
        <ParticleComponent width="50%" height="50%" />
      </div>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Paper
          ref={vantaRef}
          elevation={3}
          sx={{ p: 4 }}
          style={{ borderRadius: "5px" }}
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ color: "white" }}
          >
            Technical Skills and Experience
          </Typography>
          <Box
            sx={{
              height: 400,
              backgroundColor: "rgba(240, 240, 240, 0.8)",
              backdropFilter: "blur(10px)",
              padding: "20px",
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis type="category" dataKey="name" />
                <Tooltip />
                <Legend />
                <Bar dataKey="years" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Skills;
