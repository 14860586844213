import React from "react";
import { Typography, Box, Grid, Paper, List, ListItem } from "@mui/material";

const PortfolioContent = () => {
  return (
    <Box className="space-y-6">
      <Typography variant="body1" className="mb-4">
        Hi, welcome to my portfolio. I'm Merlin a dedicated software engineer
        with over 14 years of experience, specializing in creating dynamic and
        user-friendly software solutions.
      </Typography>

      <Box className="mt-2">
        <Box className="flex items-center mb-2">
          <Typography
            variant="h5"
            style={{ textAlign: "center" }}
            className="text-lg font-semibold pb-2"
          >
            Background
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          At NYSHEX, I spearheaded the development of interactive tables,
          calendars, and visualization features for the shipping industry using
          React and Material-UI. My work involved handling complex data
          integrations and creating scalable software solutions for the
          Non-Vessel Operating Common Carrier (NVOCC) space.
        </Typography>
        <Typography variant="body1" paragraph>
          Previously, at ActionFace, I excelled as a Full Stack developer,
          building a sophisticated 3D customizer with Ruby on Rails and 3JS. I
          collaborated with QA teams to resolve bugs and implemented dynamic
          shaders for 3D object customization, enhancing user experience.
        </Typography>
        <Typography variant="body1" paragraph>
          During my tenure at Intel, I played a critical role in managing system
          server processes and hardware for the Intel Sports team’s volumetric
          capture system. This involved delivering interactive volumetric
          content, troubleshooting issues, and training new team members on
          system operations.
        </Typography>
        <Typography variant="body1" paragraph>
          I also have a diverse background in developing innovative solutions,
          such as Parlay.bot, a web app for visualizing player stats across NFL,
          NBA, and MLB using React and Flask; SpeechSynth StreamChat, a web app
          that allows streamers to assign personalized voices to chatters using
          React and AWS Polly; and OhNo.tv, a streaming web app with blockchain
          integration for donations via NFT or crypto using Ruby on Rails and
          Solidity.
        </Typography>
        <Typography variant="body1" paragraph>
          I’m originally from Kenosha, Wisconsin, where I studied at the
          University of Wisconsin-Milwaukee and later continued my studies in
          San Francisco, California. My professional journey has allowed me to
          work with a variety of technologies and collaborate with talented
          individuals across different industries.
        </Typography>
        <Typography variant="body1" paragraph>
          I am passionate about producing interactive experiences and actively
          seek opportunities as a Full Stack Engineer or Interactive Designer.
          Feel free to explore my projects and get in touch with me online. I
          hope you enjoy my work.
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} mt={2}>
          <Paper
            className="p-4 h-full mt-2"
            style={{ background: "none", border: "none", boxShadow: "none" }}
          >
            <Box className="flex items-center mb-2 ">
              <Typography
                variant="h5"
                style={{ textAlign: "center" }}
                className="text-lg font-semibold"
              >
                Professional Experience
              </Typography>
            </Box>
            <List className="list-disc pl-5 space-y-2">
              <ListItem>
                <Typography variant="body2">
                  <strong>NYSHEX:</strong> Developed interactive tables,
                  calendars, and visualization features for the shipping
                  industry using React and Material-UI.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  <strong>ActionFace:</strong> Built a sophisticated 3D
                  customizer with Ruby on Rails and 3JS, implementing dynamic
                  shaders for 3D object customization.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  <strong>Intel:</strong> Managed system server processes and
                  hardware for the Intel Sports team's volumetric capture
                  system.
                </Typography>
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} mt={2}>
          <Paper
            className="p-4 h-full"
            style={{ background: "none", border: "none", boxShadow: "none" }}
          >
            <Box className="flex items-center mb-2">
              <Typography
                variant="h5"
                style={{ textAlign: "center" }}
                className="text-lg font-semibold"
              >
                Notable Projects
              </Typography>
            </Box>
            <List className="list-disc pl-5 space-y-2">
              <ListItem>
                <Typography variant="body2">
                  <strong>Parlay.bot:</strong> Web app for visualizing player
                  stats across NFL, NBA, and MLB using React and Flask.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  <strong>SpeechSynth StreamChat:</strong> Web app allowing
                  streamers to assign personalized voices to chatters using
                  React and AWS Polly.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  <strong>OhNo.tv:</strong> Streaming web app with blockchain
                  integration for donations via NFT or crypto using Ruby on
                  Rails and Solidity.
                </Typography>
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PortfolioContent;
