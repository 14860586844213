import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  CssBaseline,
  Link as MuiLink,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Home from "./Home";
import Work from "./Work";
import Contact from "./Contact";
import "./App.css";
import Skills from "./Skills";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#388e3c",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div id="root">
          <AppBar
            position="static"
            style={{
              zIndex: 1,
              background: "none",
              boxShadow: "none",
              color: "white",
            }}
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Merlin Jackson
              </Typography>
              <MuiLink
                component={Link}
                to="/"
                color="inherit"
                underline="none"
                sx={{ mx: 2 }}
              >
                About
              </MuiLink>

              <MuiLink
                component={Link}
                to="/work"
                color="inherit"
                underline="none"
                sx={{ mx: 2 }}
              >
                Work
              </MuiLink>

              <MuiLink
                component={Link}
                to="/skills"
                color="inherit"
                underline="none"
                sx={{ mx: 2 }}
              >
                Skills
              </MuiLink>

              <MuiLink
                component={Link}
                to="/contact"
                color="inherit"
                underline="none"
                sx={{ mx: 2 }}
              >
                Contact
              </MuiLink>
            </Toolbar>
          </AppBar>
          <main>
            <div className="container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/work" element={<Work />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/skills" element={<Skills />} />
              </Routes>
            </div>
          </main>
          <footer className="footer">
            <Container>
              <Typography variant="body2" color="text.secondary" align="center">
                &copy; 2024 Merlin Jackson
              </Typography>
            </Container>
          </footer>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
